import React from 'react';
import './Preloader.scss'
const Preloader = () => {
  return (
    <div className="preloader ">
      <meta http-equiv="X-UA-Compatible" content="IE=7" lang="en-US" />
      <h2> <span>Ra</span>kesh <span>Sa</span>h</h2>
    </div>
  );
};

export default Preloader;